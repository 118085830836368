/* Note: Google Fonts are self-hosted */

/* Termina */
@import url("https://use.typekit.net/vov3gml.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --spring-animation-speed: 500ms;
  --navbar-height: 100px;

  isolation: isolate;
}

/* Avoid text overflows */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/* Make smooth scroll. Used in blog when jumping through the table of contents */
html {
  scroll-behavior: smooth;
  scroll-padding-top: var(--navbar-height);
}

body {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: rgba(0, 0, 0, 0.1);
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  max-width: 100%;
  overflow-x: hidden;
}

@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-termina font-medium;
  }

  body {
    @apply bg-content-primary-on-dark antialiased;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .content-just-start {
    align-content: start;
  }

  .content-just-end {
    align-content: end;
  }
}
